<script setup lang="ts">
import { ref } from 'vue';
import NavLink from '@/Components/NavLink.vue';
import ResponsiveNavLink from '@/Components/ResponsiveNavLink.vue';
import { Head, Link } from '@inertiajs/vue3';
import logoUrl from '../../assets/demergo-logo.png';
import Dropdown from '@/Components/Dropdown.vue';
import DropdownLink from '@/Components/DropdownLink.vue';

const showingNavigationDropdown = ref(false);
</script>

<template>
    <Head>
        <title>Demergo | Demenz & Ergotherapie | Weiterbildung zur „FachergotherapeutIn Demenz nach Gudrun Schaade“ | Kursleitung Sarah Bretschneider</title>
        <meta name="keywords" content="Demergo,Demenz,Ergotherapie,Weiterbildung,Qualifikation,Ergotherapeut,Fachergotherapeut,Gudrun,Schaade,Dorothee,Danke,Sarah,Bretschneider,Albertinen,Akademie">
        <meta name="description" content="Informationen rund um die Demergo-Weiterbildung zur „FachergotherapeutIn Demenz nach Gudrun Schaade“. Zusätzlich bietet die Webseite ein TherapeutInnen-Verzeichnis an, die Ihre Suche nach qualifizierten ErgotherapeutInnen unterstützt. Das Verzeichnis beinhaltet AbsolventInnen der Weiterbildung.">
        <meta name="robots" content="index, follow, all" />
        <meta name="city" content="Hamburg">
        <meta name="country" content="Germany">
        <meta name="state" content="Hamburg">
        <meta name="author" content="Sarah Bretschneider">
        <meta property="og:type" content="website">
        <meta property="og:url" content="https://demergo.de">
        <meta property="og:title" content="Demergo | Demenz & Ergotherapie | Weiterbildung zur „FachergotherapeutIn Demenz nach Gudrun Schaade“ | Kursleitung Sarah Bretschneider">
        <meta property="og:description" content="Informationen rund um die Demergo-Weiterbildung zur „FachergotherapeutIn Demenz nach Gudrun Schaade“. Zusätzlich bietet die Webseite ein TherapeutInnen-Verzeichnis an, die Ihre Suche nach qualifizierten ErgotherapeutInnen unterstützt. Das Verzeichnis beinhaltet AbsolventInnen der Weiterbildung.">
        <meta name="twitter:card" content="summary">
        <meta name="twitter:url" content="https://demergo.de">
        <meta name="twitter:title" content="Demergo | Demenz & Ergotherapie | Weiterbildung zur „FachergotherapeutIn Demenz nach Gudrun Schaade“ | Kursleitung Sarah Bretschneider">
        <meta name="twitter:description" content="Informationen rund um die Demergo-Weiterbildung zur „FachergotherapeutIn Demenz nach Gudrun Schaade“. Zusätzlich bietet die Webseite ein TherapeutInnen-Verzeichnis an, die Ihre Suche nach qualifizierten ErgotherapeutInnen unterstützt. Das Verzeichnis beinhaltet AbsolventInnen der Weiterbildung.">
    </Head>

    <div>
        <div class="min-h-screen bg-white flex justify-between flex-col">
            <div>
                <nav class="bg-white border-b border-gray-100">
                    <!-- Primary Navigation Menu -->
                    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        <div class="flex justify-between h-16">
                            <div class="flex">
                                <!-- Logo -->
                                <div class="shrink-0 flex items-center">
                                    <Link :href="route('demergo.home')">
                                        <img :src="logoUrl" alt="Demergo Logo" class="block h-12 w-auto" />
                                    </Link>
                                </div>

                                <!-- Navigation Links -->
                                <div class="hidden space-x-8 lg:-my-px lg:ms-10 lg:flex">
                                    <NavLink :href="route('demergo.home')" :active="route().current('demergo.home')">
                                        Startseite
                                    </NavLink>

                                    <NavLink :href="route('demergo.education')" :active="route().current('demergo.education')">
                                        Fortbildung
                                    </NavLink>

                                    <NavLink :href="route('demergo.team')" :active="route().current('demergo.team')" title="Das aktuelle Demergo Team">
                                        Demergo Team
                                    </NavLink>

                                    <NavLink :href="route('demergo.occupational_therapy')" :active="route().current('demergo.occupational_therapy')">
                                        Ergotherapie bei Demenz
                                    </NavLink>

                                    <NavLink :href="route('demergo.gbr')" :active="route().current('demergo.gbr')">
                                        Demergo GbR
                                    </NavLink>

                                    <NavLink :href="route('demergo.therapists')" :active="route().current('demergo.therapists')">
                                        TherapeutInnen
                                    </NavLink>

                                    <NavLink :href="route('demergo.contact')" :active="route().current('demergo.contact')" class="hidden xl:inline-flex">
                                        Kontakt
                                    </NavLink>
                                </div>
                            </div>

                            <div class="hidden lg:flex lg:items-center lg:ms-6">
                                <!-- Settings Dropdown -->
                                <div v-if="$page.props.auth.user" class="ms-3 relative">
                                    <Dropdown align="right" width="48">
                                        <template #trigger>
                                            <span class="inline-flex rounded-md">
                                                <button
                                                    type="button"
                                                    class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-gray-500 bg-white hover:text-gray-700 focus:outline-none transition ease-in-out duration-150"
                                                >
                                                    <!-- {{ $page.props.auth.user.name }} -->
                                                    Dein Bereich

                                                    <svg
                                                        class="ms-2 -me-0.5 h-4 w-4"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 20 20"
                                                        fill="currentColor"
                                                    >
                                                        <path
                                                            fill-rule="evenodd"
                                                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                                            clip-rule="evenodd"
                                                        />
                                                    </svg>
                                                </button>
                                            </span>
                                        </template>

                                        <template #content>
                                            <DropdownLink :href="route('profile.edit')"> Übersicht </DropdownLink>
                                            <DropdownLink :href="route('profile.edit')"> Konto </DropdownLink>
                                            <DropdownLink :href="route('logout')" method="post" as="button">
                                                Abmelden
                                            </DropdownLink>
                                        </template>
                                    </Dropdown>
                                </div>
                                <NavLink v-else :href="route('login')" class="text-bold">
                                    Anmelden
                                </NavLink>
                            </div>

                            <!-- Hamburger -->
                            <div class="-me-2 flex items-center lg:hidden">
                                <button
                                    @click="showingNavigationDropdown = !showingNavigationDropdown"
                                    class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                                >
                                    <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                        <path
                                            :class="{
                                                hidden: showingNavigationDropdown,
                                                'inline-flex': !showingNavigationDropdown,
                                            }"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M4 6h16M4 12h16M4 18h16"
                                        />
                                        <path
                                            :class="{
                                                hidden: !showingNavigationDropdown,
                                                'inline-flex': showingNavigationDropdown,
                                            }"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M6 18L18 6M6 6l12 12"
                                        />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>

                    <!-- Responsive Navigation Menu -->
                    <div
                        :class="{ block: showingNavigationDropdown, hidden: !showingNavigationDropdown }"
                        class="lg:hidden"
                    >
                        <div class="pt-2 pb-3 space-y-1">
                            <ResponsiveNavLink :href="route('demergo.home')" :active="route().current('demergo.home')">
                                Startseite
                            </ResponsiveNavLink>

                            <ResponsiveNavLink :href="route('demergo.education')" :active="route().current('demergo.education')">
                                Fortbildung
                            </ResponsiveNavLink>

                            <ResponsiveNavLink :href="route('demergo.team')" :active="route().current('demergo.team')">
                                Demergo Team
                            </ResponsiveNavLink>

                            <ResponsiveNavLink :href="route('demergo.occupational_therapy')" :active="route().current('demergo.occupational_therapy')">
                                Ergotherapie bei Demenz
                            </ResponsiveNavLink>

                            <ResponsiveNavLink :href="route('demergo.therapists')" :active="route().current('demergo.therapists')">
                                TherapeutInnen
                            </ResponsiveNavLink>

                            <ResponsiveNavLink :href="route('demergo.gbr')" :active="route().current('demergo.gbr')">
                                Demergo GbR
                            </ResponsiveNavLink>

                            <ResponsiveNavLink :href="route('demergo.contact')" :active="route().current('demergo.contact')">
                                Kontakt
                            </ResponsiveNavLink>

                            <ResponsiveNavLink :href="route('login')" :active="route().current('login')">
                                Anmelden
                            </ResponsiveNavLink>
                        </div>
                    </div>
                </nav>

                <!-- Page Heading -->
                <header class="bg-white shadow" v-if="$slots.header">
                    <div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                        <slot name="header" />
                    </div>
                </header>

                <!-- Page Content -->
                <main>
                    <slot />
                </main>
            </div>
            
            <div class="bg-gray-100">
                <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div class="flex justify-between sm:justify-end items-center py-3">
                        <a :href="route('demergo.contact')" class="p-3">
                            Kontakt
                        </a>
                        <a :href="route('demergo.privacy_policy')" class="p-3">
                            Datenschutz
                        </a>
                        <a :href="route('demergo.legal_terms')" class="p-3">
                            Impressum
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
